<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <Avatar size="large" class="primary" icon="pi pi-building" />
                  </div>
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">
                      Institution Details
                    </div>
                    <div class="text-sm text-500">
                      Details of institution record
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <Breadcrumb :home="{
                  icon: 'pi pi-arrow-left text-lg mr-3',
                  label: 'Back',
                  command: () => {
                    $router.go(-1);
                  },
                }" :model="[
                  {
                    label: 'Caps Institutions List',
                    to: '/caps_institutions_list',
                  },
                  {
                    label: 'Institution Details',
                    to: `/caps_institutions_list/view/${id}`,
                  },
                ]" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
    </template>
    <section class="page-section">
      <div class="container-fluid">
        <div class="grid">
          <div class="col comp-grid">
            <div class="card nice-shadow-18">
              <div>
                <div class="relative-position" style="min-height: 100px">
                  <template v-if="!loading && item">
                    <div class="row q-col-gutter-x-md">
                      <div class="col">
                        <div class="grid align-items-center">
                          <div class="col mr-2 font-bold">
                            <image-viewer image-size="large" image-preview-size="" :src="item.logo" width="50px"
                              height="50px" :num-display="1">
                            </image-viewer>
                          </div>
                          <div class="col-11 text-2xl font-bold">
                            {{ item.institution_name }}
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                    <TabView>
                      <TabPanel>
                        <template #header>
                          <div class="flex gap-2 align-items-center">
                            <span>Institution Details</span>
                          </div>
                        </template>
                        <div class="row q-col-gutter-x-md">
                          <div class="col-12">
                            <div class="grid align-items-center">
                              <div class="col md:col-3">
                                Institution Name:
                              </div>
                              <div class="col font-bold">{{ item.institution_name }}</div>
                            </div>
                            <hr />
                            <div class="grid align-items-center">
                              <div class="col md:col-3">
                                Telephone:
                              </div>
                              <div class="col font-bold">{{ item.telephone }}</div>
                            </div>
                            <hr />
                            <div class="grid align-items-center">
                              <div class="col md:col-3">
                                Location:
                              </div>
                              <div class="col font-bold">{{ item.location }}</div>
                            </div>
                            <hr />
                            <div class="grid align-items-center">
                              <div class="col md:col-3">
                                Digital Address:
                              </div>
                              <div class="col font-bold">{{ item.digital_address }}</div>
                            </div>
                            <hr />
                            <div class="grid align-items-center">
                              <div class="col md:col-3">
                                Institutional Email:
                              </div>
                              <div class="col font-bold">{{ item.institutional_email }}</div>
                            </div>
                            
                            <div class="grid align-items-center">
                              <div class="col md:col-3">
                                Website Url:
                              </div>
                              <div class="col font-bold">{{ item.website_url }}</div>
                            </div>
                            <hr />
                            <div class="grid align-items-center">
                              <div class="col md:col-3">
                                Affiliated Institution:
                              </div>
                              <div class="col font-bold">{{ item.affiliated_institution }}</div>
                            </div>
                            <hr />
                            <div class="grid align-items-center">
                              <div class="col md:col-3">
                                Year Of Establishment:
                              </div>
                              <div class="col font-bold">{{ item.year_of_establishment }}</div>
                            </div>
                            <hr />
                            <div class="grid align-items-center">
                              <div class="col md:col-3">
                                Accredited:
                              </div>
                              <div class="col font-bold">{{ item.is_accredited  }}</div>
                            </div>
                            <hr />
                            <div class="grid align-items-center">
                              <div class="col md:col-3">
                                Start Of Accreditation:
                              </div>
                              <div class="col font-bold">
                                {{ $utils.humanDate(item.start_of_accreditation) }}
                              </div>
                            </div>
                            <hr />
                            <div class="grid align-items-center">
                              <div class="col md:col-3">
                                End Of Accreditation:
                              </div>
                              <div class="col font-bold">
                                {{ $utils.humanDate(item.end_of_accreditation) }}
                              </div>
                            </div>
                            <hr />
                            <div class="grid align-items-center">
                              <div class="col md:col-3">
                                Registrar Name:
                              </div>
                              <div class="col font-bold">{{ item.registrar_name }}</div>
                            </div>
                            <hr />
                            <div class="grid align-items-center">
                              <div class="col md:col-3">
                                Registrar Phone Number:
                              </div>
                              <div class="col font-bold">{{ item.registrar_phone_number }}</div>
                            </div>
                            <hr />
                            <div class="flex justify-content-start">
                              <Menubar class="p-0" ref="actionMenu" :model="getActionMenuModel(item)" />
                            </div>
                          </div>
                        </div>
                      </TabPanel>

                      <TabPanel>
                        <template #header>
                          <div>Institution Campuses</div>
                        </template>
                        <div class="reset-grid">
                          <list-institutioncampuses-page is-sub-page ref="institutionCampusesListPage"
                            field-name="institution_campuses.caps_institution_id"
                            :field-value="item.caps_institution_id" :show-header="false" :limit="10">
                          </list-institutioncampuses-page>
                        </div>
                      </TabPanel>

                      <TabPanel>
                        <template #header>
                          <div class="flex gap-2 align-items-center">
                            <span>Programmes List</span>
                          </div>
                        </template>
                        <div>
                          <Caps-Programmes-List is-sub-page ref="institutionProgrammesListPage" :show-header="false"
                            :limit="10" field-name="caps_institution_id"
                            :field-value="item.caps_institution_id"></Caps-Programmes-List>
                        </div>
                      </TabPanel>
                    </TabView>

                  </template>
                  <template v-if="loading">
                    <div>
                      <table class="p-datatable-table w-full">
                        <tbody>
                          <tr v-for="n in 5" :key="n">
                            <td class="text-left" style="width: 30%">
                              <Skeleton height="2rem" class="m-2" />
                            </td>
                            <td class="text-left" style="width: 70%">
                              <Skeleton height="2rem" class="m-2" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="p-3 text-center">
                      <ProgressSpinner style="width: 50px; height: 50px" />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
//[--PAGE-IMPORT-STATEMENT--]
import { defineAsyncComponent, ref } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { PageMixin } from "../../mixins/page.js";
import { ViewPageMixin } from "../../mixins/viewpage.js";
import CapsProgrammesList from "../caps_programmes_list/list.vue";
import listInstitutioncampusesPage from "../institution_campuses/list.vue";

export default {
  name: "viewCapsinstitutionslistPage",
  components: {
    //[--PAGE-COMPONENT-NAME--]
    CapsProgrammesList, listInstitutioncampusesPage,
  },
  mixins: [PageMixin, ViewPageMixin],
  props: {
    pageName: {
      type: String,
      default: "caps_institutions_list",
    },
    idName: {
      type: String,
      default: "caps_institution_id",
    },
    routeName: {
      type: String,
      default: "caps_institutions_listview",
    },
    pagePath: {
      type: String,
      default: "caps_institutions_list/view",
    },
    apiPath: {
      type: String,
      default: "caps_institutions_list/view",
    },
  },
  data() {
    return {
      item: {
        default: {},
      },
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Caps Institutions List Details";
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["caps_institutions_list/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("caps_institutions_list/setCurrentRecord", value);
      },
    },
  },
  meta() {
    return {
      title: this.pageTitle,
    };
  },
  methods: {
    ...mapActions("caps_institutions_list", ["fetchRecord", "deleteRecord"]),
    getActionMenuModel(data) {
      return [
        {
          label: "Delete from List",
          command: (event) => { this.deleteItem(data.caps_institution_id) },
          icon: "pi pi-minus-circle"
        }
      ];
    },
  },
  watch: {
    $route(to, from) {
      //only fetch data when navigated to this page
      if (to.name == this.routeName) {
        //this.load();
      }
    },
  },
  async mounted() { },
  async created() { },
};
</script>
<style scoped></style>
<style scoped></style>
